import { inject, Injectable } from '@angular/core'
import { filter } from 'rxjs'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { PositionEnum, WholesalesService, WholesaleStatusEnum } from '@core/api'

@Injectable()
export class WsFmcgPendingPromotionService {
  userDataService = inject(UserDataService)
  wholesalesService = inject(WholesalesService)

  hasEligible = false

  showWsFmcgPromotionPopup = false

  constructor() {
    this.checkHasPendingPromotion()
  }

  checkHasPendingPromotion() {
    this.userDataService.userData$
      .pipe(
        filter((userData) => {
          const isOwnerOrManager = [PositionEnum.ShopOwner, PositionEnum.ShopManager].includes(
            userData?.position as PositionEnum,
          )
          return (
            isOwnerOrManager &&
            (!!userData?.myWholesaleId || userData?.myWholesaleStatus === WholesaleStatusEnum.Approved)
          )
        }),
      )
      .subscribe((userData) => {
        this.wholesalesService
          .wholesalesHasPendingDistributorPromotionRetrieve({
            id: userData!.myWholesaleId as number,
          })
          .subscribe((res) => {
            const hasEligible = !!res?.hasEligible
            this.hasEligible = hasEligible
            this.showWsFmcgPromotionPopup = hasEligible
          })
      })
  }
}
